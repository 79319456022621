import { GraphQLClient } from 'graphql-request';

export const shopifyGraphQLClient = new GraphQLClient(import.meta.env.VITE_STOREFRONT_ENDPOINT, {
	headers: {
		'X-Shopify-Storefront-Access-Token': import.meta.env.VITE_STOREFRONT_TOKEN,
	},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function chain(...callbacks: any[]): (...args: any[]) => void {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (...args: any[]) => {
		for (const callback of callbacks) {
			if (typeof callback === 'function') {
				callback(...args);
			}
		}
	};
}

// Sets a CSS property on an element, and returns a function to revert it to the previous value.
export const setStyle = (element: HTMLElement, style: keyof CSSStyleDeclaration, value: string | null) => {
	const cur = element.style[style];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	element.style[style as any] = value as any;
	return () => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(element.style[style as any] as any) = cur;
	};
};

export const isScrollable = (node: Element): boolean => {
	const style = window.getComputedStyle(node);
	return style ? /(auto|scroll)/.test(style.overflow + style.overflowX + style.overflowY) : false;
};
